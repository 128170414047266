import { createSlice } from "@reduxjs/toolkit";

import { decodePayload } from "../../api-client/decodePayload";
import { Language, LanguageMap } from "../../i18n/useLanguage";

const name = "dealerAccountInfo";

const dealerAccountInfoSlice = createSlice({
  initialState: decodePayload(
    window.dealerAccountInfoPayload
  ) as DealerAccountInfoPayload,
  name,
  reducers: {
    next(_state, { payload }: { payload: DealerAccountInfoPayload }) {
      return payload;
    },
  },
});

export const _dealerAccountInfo = dealerAccountInfoSlice.reducer;

export type DealerCountry = "CA" | "US";

export const DealerEnableXtimeAppointmentIntegrationHide = "HIDE";
export const DealerEnableXtimeAppointmentIntegrationShow = "SHOW";

export type OriginalEquipmentFilter =
  | "ALL_ALL"
  | "ALL_OE"
  | "AUTH_ALL"
  | "AUTH_OE"
  | "HIDE_ALL"
  | "HIDE_OE";

export type DealerAccountInfoPayload = {
  dealerAccessoryPricingLabelOverride: {
    en: string;
    fr: string;
  };
  dealerAccessoryPricingNote: {
    en: string;
    fr: string;
  };
  dealerAccessoryShowSecondaryManufacturers: boolean;
  dealerAddress: string;
  dealerAllSeasonTireSpeedRatingOffset: string;
  dealerAppointmentIntegrationId: string;
  dealerAppointmentLeadTime: number;
  dealerAtdConnectCustomerNumber: string;
  dealerAtdConnectIntegrationEnabled: boolean;
  dealerAtdConnectLocationCode: string;
  dealerCarousels: {
    home_primary: CarouselItem[];
    home_secondary: CarouselItem[];
    order_summary: CarouselItem[];
  };
  dealerCity: string;
  dealerContactEmail: string;
  dealerContactPhone: string;
  dealerCountry: DealerCountry;
  dealerDefaultLanguage: Language;
  dealerDefaultSearchSeason: string;
  dealerDefaultTemplate: string;
  dealerEnableAccessoryInstallationLabourPrice: boolean;
  dealerEnableAccessorySearch: boolean;
  dealerEnableAdvancedFooter: boolean;
  dealerEnableAdvancedSearchModal: boolean;
  dealerEnableAdvancedSearchPartNumber: string;
  dealerEnableAdvancedSearchTiresize: string;
  dealerEnableAdvancedSearchVehicleChassis: string;
  dealerEnableAdvancedSearchVehicleModel: string;
  dealerEnableBookingSystem: boolean;
  dealerEnableCaptcha: boolean;
  dealerEnableCartReview: boolean;
  dealerEnableCustomerAccountConfirmation: boolean;
  dealerEnableEcommerceRetailSite: boolean;
  dealerEnableFeaturedItems: boolean;
  dealerEnableGuestAccountConfirmation: boolean;
  dealerEnableGuestAccountCreation: boolean;
  dealerEnableHidePublicPricing: string;
  dealerEnableHomepageCarousel: boolean;
  dealerEnableHomepagePackageModule: boolean;
  dealerEnableIncentivePricing: string;
  dealerEnableLiveChat: boolean;
  dealerEnableMembershipPricing: string;
  dealerEnableMerchandiseSearch: boolean;
  dealerEnableNationalPriceCheck: boolean;
  dealerEnableNationalStockCheck: boolean;
  dealerEnablePackageSearch: boolean;
  dealerEnablePaymentCalculator?: string;
  dealerEnablePopUpQuote: boolean;
  dealerEnablePricingAnalysis: "AUTH" | "HIDE" | "SHOW";
  dealerEnablePromptToOrderTires: boolean;
  dealerEnableRetailSite: boolean;
  dealerEnableShowCartModalOnAddItem: boolean;
  dealerEnableShowHomepageFooterCatalogue: boolean;
  dealerEnableShowHomepageWidget: boolean;
  dealerEnableShowMsrpForItems: boolean;
  dealerEnableShowPricingLastUpdate: string;
  dealerEnableShowSubmodelSkipSelection: boolean;
  dealerEnableShowSubmodelTiresizeSelection: boolean;
  dealerEnableStockForCustomers: boolean;
  dealerEnableTireSearch: boolean;
  dealerEnableWheelSearch: boolean;
  dealerEnableWheelTireInstallationLabourPrice: boolean;
  dealerEnableXtimeAppointmentIntegration:
    | typeof DealerEnableXtimeAppointmentIntegrationHide
    | typeof DealerEnableXtimeAppointmentIntegrationShow;
  dealerExternalAppointmentUrlEn: string;
  dealerExternalAppointmentUrlFr: string;
  dealerFillEcommerceOrderFromCustomStockSource: string;
  dealerHidePackageEditor: string;
  dealerHours: DealerHours;
  dealerId: string;
  dealerIframeHiddenHeader: boolean;
  dealerIgnoreAccessoryStock: boolean;
  dealerIgnoreMerchandiseStock: boolean;
  dealerIgnoreTireStock: boolean;
  dealerIgnoreVehicleSortOrder: string;
  dealerIgnoreWheelStock: boolean;
  dealerIsActive: boolean;
  dealerLanguageSet: Language[];
  dealerLatitude: string;
  dealerLongitude: string;
  dealerMapExitLink: string;
  dealerMerchandisePricingLabelOverride: LanguageMap;
  dealerMerchandisePricingNote: LanguageMap;
  dealerName: string;
  dealerNationalAccountId: string;
  dealerNationalAccountName: string;
  dealerNationalAccountRegionCode: string;
  dealerNationalAccountRegionId: string;
  dealerNumberOfProductsToShowBeforePagination: number;
  dealerPackageEditorAdminOnly: "0" | "1";
  dealerPackagePricingLabelOverride: LanguageMap;
  dealerPackagePricingNote: LanguageMap;
  dealerPackageShowSecondaryManufacturers: boolean;
  dealerPostalCode: string;
  dealerProductFilters: {
    accessoryCategories: string[];
    categoryFilter: boolean;
    originalEquipmentFilter: OriginalEquipmentFilter;
    pricingFilter: boolean;
    runFlatTireFilter: boolean;
    seasonCodes: {
      [key: string]: LanguageMap;
    };
    tireManufacturers: {
      [key: string]: {
        part_manufacturer_id: string;
        part_manufacturer_logo: string;
        part_manufacturer_name: string;
        visibility: string;
      };
    };
    wheelManufacturers: {
      [key: string]: string;
    };
  };
  dealerRegionalOnly: boolean;
  dealerRetailSiteCustomFaviconImage: string;
  dealerRetailSiteCustomLogo: string;
  dealerRetailSiteCustomTitle: LanguageMap;
  dealerRetailSiteDefaultPartQuantityFromVehicle: boolean;
  dealerRetailSiteForceUserAuthentication: boolean;
  dealerRetailSiteGoogleAnalyticsCode: string;
  dealerRetailSiteGoogleMapsExternalUrl: string;
  dealerRetailSiteGoogleTagManagerCode: string;
  dealerRetailSiteHomeProductTileSearchPath: string;
  dealerRetailSiteHomeWidgetPlacement: string;
  dealerRetailSiteHomepageProductTypeTileLayout: string;
  dealerRetailSiteProductFilterLayout: string;
  dealerRetailSiteProductListTileLayout: string;
  dealerRetailSiteSocialMediaUrlFacebook: string;
  dealerRetailSiteSocialMediaUrlInstagram: string;
  dealerRetailSiteSocialMediaUrlTwitter: string;
  dealerRetailSiteStartPage: string;
  dealerState: string;
  dealerSubmodelTileLayout: string;
  dealerSummerTireSpeedRatingOffset: string;
  dealerTaxPercent: number;
  dealerTimeZone: string;
  dealerTirePricingLabelOverride: LanguageMap;
  dealerTirePricingNote: LanguageMap;
  dealerTireShowSecondaryManufacturers: boolean;
  dealerUrlCode: string;
  dealerWebsiteUrl: string;
  dealerWheelPricingLabelOverride: LanguageMap;
  dealerWheelPricingNote: LanguageMap;
  dealerWheelShowSecondaryManufacturers: boolean;
  dealerWinterTireSpeedRatingOffset: string;
  enableFinancing: string;
  financingUrl: string;
  legalFooter: LanguageMap;
  minimumAccessoryYear: string;
  minimumPackageYear: string;
  minimumTireYear: string;
  minimumWheelYear: string;
  placeholderImageUrl: string;
  promotionalImageTire: LanguageMap;
  supportConsumerOrder: boolean;
  supportDealerOrder: boolean;
  supportUserConsumerOrder: boolean;
  supportUserDealerOrder: boolean;
} | null;

export type HAlign = "center" | "left" | "right";
export type VAlign = "bottom" | "center" | "top";

export type CarouselItem = {
  hideFromNational: string;
  imageURL: LanguageMap;
  linkBody: LanguageMap;
  linkHAlign: HAlign;
  linkTarget: string;
  linkText: LanguageMap;
  linkURL: LanguageMap;
  linkVAlign: string;
  resourceType: string;
  rotationHoldDelayMS: string;
  sortOrder: number | string;
  subtitle: LanguageMap;
  title: LanguageMap;
  titleHAlign: HAlign;
  titleVAlign: string;
  videoAutoPlay: boolean;
  videoLoop: boolean;
  videoMpeg4Url: LanguageMap;
  videoMute: boolean;
  videoOggUrl: LanguageMap;
  videoWebMUrl: LanguageMap;
  videoYouTubeHeight: number;
  videoYouTubeUrl: LanguageMap;
};

export type DealerHours = {
  FRI: DaySchedule;
  MON: DaySchedule;
  SAT: DaySchedule;
  SUN: DaySchedule;
  THU: DaySchedule;
  TUE: DaySchedule;
  WED: DaySchedule;
};

export type DaySchedule = {
  close: string;
  day: LanguageMap;
  open: string;
};

declare global {
  interface Window {
    dealerAccountInfoPayload: DealerAccountInfoPayload;
  }
}

import React, { ComponentType, Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";

import "./redesign/sites/common.css";

// eslint-disable-next-line perfectionist/sort-imports
import { ErrorBoundary } from "./redesign/blocks/Error/ErrorBoundary";
import { ReduxProvider } from "./redesign/stores/ReduxProvider";
import { LinearProgress } from "./redesign/ui/LinearProgress";
import { bugsnagStart } from "./redesign/util/bugsnag";

bugsnagStart();

let counter = 0;

const getTemplateName = (): string => {
  const templateName =
    [
      window.dealerAccountInfoPayload?.dealerDefaultTemplate,
      window.nationalAccountInfoPayload?.nationalDefaultTemplate,
      window.groupAccountInfoPayload?.dealerGroupDefaultTemplate,
    ].find(Boolean) || "generic";

  return templateName;
};

function getComponent(templateName): ComponentType {
  const siteName = window.dealerAccountInfoPayload
    ? "DealerSite"
    : "NationalSite";

  switch (templateName) {
    case "Acura Site 2.0":
      return lazy(() => import(`./redesign/sites/acura/${siteName}`));
    case "Audi Site 2.0":
      return lazy(() => import(`./redesign/sites/audi/${siteName}`));
    case "BMW Site 2.0":
      return lazy(() => import(`./redesign/sites/bmw/${siteName}`));
    case "Chrysler Site 2.0":
      return lazy(() => import(`./redesign/sites/chrysler/${siteName}`));
    case "Ford Site 2.0":
      return lazy(() => import(`./redesign/sites/ford/${siteName}`));
    case "GM Site 2.0":
      return lazy(() => import(`./redesign/sites/gm/${siteName}`));
    case "Honda Site 2.0":
      return lazy(() => import(`./redesign/sites/honda/${siteName}`));
    case "Hyundai Site 2.0":
      return lazy(() => import(`./redesign/sites/hyundai/${siteName}`));
    case "KIA Site 2.0":
      return lazy(() => import(`./redesign/sites/kia/${siteName}`));
    case "Lexus Site 2.0":
      return lazy(() => import(`./redesign/sites/lexus/${siteName}`));
    case "Mazda Site 2.0":
      return lazy(() => import(`./redesign/sites/mazda/${siteName}`));
    case "Mercedes Site 2.0":
      return lazy(() => import(`./redesign/sites/mercedes/${siteName}`));
    case "Mini Site 2.0":
      return lazy(() => import(`./redesign/sites/mini/${siteName}`));
    case "Nissan Site 2.0":
      return lazy(() => import(`./redesign/sites/nissan/${siteName}`));
    case "Subaru Site 2.0":
      return lazy(() => import(`./redesign/sites/subaru/${siteName}`));
    case "Toyota Site 2.0":
      return lazy(() => import(`./redesign/sites/toyota/${siteName}`));
    case "VW Site 2.0":
      return lazy(() => import(`./redesign/sites/volkswagen/${siteName}`));
    case "Universal template Site 2.0":
    default:
      return lazy(
        () => import(`./redesign/sites/universal-template/${siteName}`)
      );
  }
}

function run(): void {
  const node = document.getElementById("app");
  if (node) {
    const templateName = getTemplateName();
    const App = getComponent(templateName);
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(
      <ReduxProvider>
        <HelmetProvider>
          <Suspense fallback={<LinearProgress className="h-0.5" />}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </Suspense>
        </HelmetProvider>
      </ReduxProvider>,
      node
    );
  } else if (counter < 5) {
    counter++;
    setTimeout(() => {
      run();
    }, 100);
  }
}

if (
  ["complete", "interactive", "loaded"].includes(document.readyState) &&
  document.body
) {
  run();
} else {
  window.addEventListener("DOMContentLoaded", run, false);
}
